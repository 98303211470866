/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        if(navigator.platform.match('Mac') !== null) {
            $('body').addClass('OSX');
        }

        scrollWidth = $.position.scrollbarWidth();


        $('.js-navigation-toggle').click(function(e) {
          e.preventDefault();
          if(window.innerWidth>=768){
            swap = 525-scrollWidth;
          }else{
            swap = window.innerWidth-scrollWidth;
          }
          if($('.innerSlide').position().left===0){
            $('.nav-primary').stop().animate({ left: $('.innerSlide').width()-swap+"px" }, 750, "easeInOutCirc" );
            $(".innerSlide").stop().animate({ left: "-"+swap+"px" }, 750, "easeInOutCirc" );
            $('body').addClass('nav_open');
          }else{
            $('.nav-primary').stop().animate({ left: $('.innerSlide').width() }, 750, "easeInOutCirc" );
            $(".innerSlide").stop().animate({ left: 0 +"px" }, 750, "easeInOutCirc" );
            $('body').removeClass('nav_open');
          }
        });

        $('.cart-customlocation-wrap, .cart-customlocation2').click(function(e) {
          e.preventDefault();
          if(window.innerWidth>=768){
            swap = 525-scrollWidth;
          }else{
            swap = window.innerWidth-scrollWidth;
          }
          if($('.innerSlide').position().left===0){
            $('.mini-cart').stop().animate({ left: $('.innerSlide').width()-swap+"px" }, 750, "easeInOutCirc" );
            $(".innerSlide").stop().animate({ left: "-"+swap+"px" }, 750, "easeInOutCirc" );
            $('body').addClass('nav_open');
          }else{
            $('.mini-cart').stop().animate({ left: $('.innerSlide').width() }, 750, "easeInOutCirc" );
            $(".innerSlide").stop().animate({ left: 0 +"px" }, 750, "easeInOutCirc" );
            $('body').removeClass('nav_open');
          }
        });

        $('#rememberme').attr('checked', true);

        $('body.logged-in #menu-additional li.menu-item.menu-item-type-custom a').first().html("my account");

        $('#menu-additional li.menu-item.menu-item-type-custom a').first().click(function(e) {
          e.preventDefault();
          if(window.innerWidth>=768){
            swap = 525-scrollWidth;
          }else{
            swap = window.innerWidth-scrollWidth;
          }
          $('.nav-login').stop().animate({ left: $('.innerSlide').width()-swap+"px" }, 750, "easeInOutCirc" );
          $(".innerSlide").stop().animate({ left: "-"+swap+"px" }, 750, "easeInOutCirc" );
          $('body').addClass('nav_open');
          
        });

        $('.login_logout').click(function(e) {
          e.preventDefault();
          $('.nav-login').stop().animate({ left: $('.innerSlide').width() }, 750, "easeInOutCirc" );
          $('.nav-primary').stop().animate({ left: $('.innerSlide').width() }, 750, "easeInOutCirc" );
          $(".innerSlide").stop().animate({ left: 0 +"px" }, 750, "easeInOutCirc" );
          $('body').removeClass('nav_open');
        });

        $('.mini_cart_item a.remove').click(function(e){
          e.preventDefault();

          var product_id = $(this).attr("data-product_id"),
              cart_item_key = $(this).attr("data-cart_item_key"),
              product_container = $(this).parents('.mini_cart_item');

          // Add loader
          product_container.block({
              message: null,
              overlayCSS: {
                  cursor: 'none'
              }
          });

          $.ajax({
              type: 'POST',
              dataType: 'json',
              url: wc_add_to_cart_params.ajax_url,
              data: {
                  action: "product_remove",
                  product_id: product_id,
                  cart_item_key: cart_item_key
              },
              success: function(response) {
                  if ( ! response || response.error ){
                      return;
                  }

                  var fragments = response.fragments;

                  // Replace fragments
                  if ( fragments ) {
                      $.each( fragments, function( key, value ) {
                          $( key ).replaceWith( value );
                      });
                  }
              }
          });
        });

        oldX = '';
        $( window ).resize(function(e) {
          if(window.innerWidth !== oldX){
              $('.nav-primary, .nav-login, .mini-cart, .nav-shipping').stop().css("left", $('.innerSlide').width() );
              $(".innerSlide").stop().css( "left", 0 +"px");

              $('.half_black_bg').css('height', 168+($('.measure').last().innerHeight()/2)+"px");
              $('body').removeClass('nav_open');
          }
          oldX = window.innerWidth;
        });
        window.onload = function(e){
         $( window ).resize();
         $('h1, h2, h3').addClass('active');
        };
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        $('.homepage_header').first().addClass('active');
        $('#menu-homepage li a').mouseenter(function() {
          $('.homepage_header').removeClass('active');
          var curPos = $(this).parent().index();
          $('.homepage_header:eq('+curPos+')').addClass('active');
        }); 


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'single_product': {
      init: function() {
        jQuery(document).ready(function() {
        
          function checkSize(){
            if (window.matchMedia("(min-width: 768px)").matches) {
              jQuery.each(jQuery(".single-product select[data-attribute_name]"), function(t, n) {
                  jQuery(n).select2({
                      minimumResultsForSearch: -1,
                      dropdownParent: jQuery(n).closest(".variations")
                  });
              });
            } else {
              jQuery.each(jQuery(".single-product select[data-attribute_name]"), function(t, n) {
                  if(jQuery(n).data('select2')){
                    jQuery(n).select2("destroy");
                  }
              });
            }
            
            if($('.woocommerce-product-gallery__image').length===1){
              scrollWidth = $.position.scrollbarWidth();
              swap = window.innerWidth-scrollWidth;
              $('.woocommerce-product-gallery__image, .woocommerce-product-gallery__wrapper').css('width', swap+"px" );
              $('.woocommerce-product-gallery__image, .woocommerce-product-gallery__wrapper').css('height', "100vh" );
            }
          }

          checkSize();
          $(window).resize(checkSize);

        });


        $('.woo-variation-raw-type-color').prop("selectedIndex", 1);

        $('.woocommerce-product-gallery__image').first().addClass('flex-active-slide');

        $('.woocommerce div.product div.images .flex-control-thumbs').after('<div class="thumb_control_prev"><div class="arrow"></div></div>');
        $('.woocommerce div.product div.images .flex-control-thumbs').after('<div class="thumb_control_next"><div class="arrow"></div></div>');

        scrollObj = $('.flex-control-thumbs');
        $('.thumb_control_prev').click(function() {
          scrollObj.stop().animate({ scrollTop: "0" });
        });

        $('.thumb_control_prev').mouseenter(function() {
          scrollObj.stop().animate({ scrollTop: "0" }, 3000);
        });

        $('.thumb_control_prev').mouseleave(function() {
          scrollObj.stop();
        });

        $('.thumb_control_next').click(function() {
          scrollObj.stop().animate({ scrollTop: scrollObj[0].scrollHeight + "px" });
        });

        $('.thumb_control_next').mouseenter(function() {
          scrollObj.stop().animate({ scrollTop: scrollObj[0].scrollHeight + "px" }, 3000);
        });

        $('.thumb_control_next').mouseleave(function() {
          scrollObj.stop();
        });

        /* WC ADDITIONAL IMAGES OVERRIDE */
        
        var wcavi_original_gallery_images = $( wc_additional_variation_images_local.gallery_images_class ).html();
        var wcavi_original_main_images    = $( wc_additional_variation_images_local.main_images_class ).html();

        // create namespace to avoid any possible conflicts
        $.wc_additional_variation_images_frontend = {
            /**
             * Get WC AJAX endpoint URL.
             *
             * @param  {String} endpoint Endpoint.
             * @return {String}
             */
            getAjaxURL: function( endpoint ) {
                return wc_additional_variation_images_local.ajax_url
                    .toString()
                    .replace( '%%endpoint%%', 'wc_additional_variation_images_' + endpoint );
            },

            isCloudZoom: function() {
                var cloudZoomClass = $( 'a.woocommerce-main-image' ).hasClass( 'cloud-zoom' );

                return cloudZoomClass;
            },

            runLightBox: function( callback ) {
                // user trigger
                $( 'form.variations_form' ).trigger( 'wc_additional_variation_images_frontend_lightbox', wc_additional_variation_images_local.gallery_images_class, wc_additional_variation_images_local.main_images_class, wcavi_original_gallery_images, wcavi_original_main_images );

                // if cloud zoom is active
                if ( $.wc_additional_variation_images_frontend.isCloudZoom() ) {

                    $( '.cloud-zoom' ).each( function() {
                        $( this ).data( 'zoom' ).destroy();
                    });

                    $( '.cloud-zoom, .cloud-zoom-gallery' ).CloudZoom();
                } else {

                    if ( $.isFunction( $.fn.prettyPhoto ) ) {
                        // lightbox
                        $( wc_additional_variation_images_local.lightbox_images ).prettyPhoto({
                            hook: 'data-rel',
                            social_tools: false,
                            theme: 'pp_woocommerce',
                            horizontal_padding: 20,
                            opacity: 0.8,
                            deeplinking: false
                        });
                    }
                }

                $( 'form.variations_form' ).trigger( 'wc_additional_variation_images_frontend_lightbox_done', [ wc_additional_variation_images_local.gallery_images_class, wc_additional_variation_images_local.main_images_class, wcavi_original_gallery_images, wcavi_original_main_images ] );

                if ( callback ) {
                    callback();
                }
            },

            reset: function( callback ) {

                if ( wc_additional_variation_images_local.custom_reset_swap == true ) {
                    var response = '';

                    $( 'form.variations_form' ).trigger( 'wc_additional_variation_images_frontend_on_reset', [ response, wc_additional_variation_images_local.gallery_images_class, wc_additional_variation_images_local.main_images_class, wcavi_original_gallery_images, wcavi_original_main_images ] );

                } else {
                    // replace the original gallery images
                    $( wc_additional_variation_images_local.gallery_images_class ).fadeOut( 50, function() {
                        $( this ).html( wcavi_original_gallery_images ).hide().fadeIn( 100, function() {
                            $.wc_additional_variation_images_frontend.runLightBox();
                        });
                    });
                }

                $( 'form.variations_form' ).trigger( 'wc_additional_variation_images_frontend_on_reset_done', [ wc_additional_variation_images_local.gallery_images_class, wc_additional_variation_images_local.main_images_class, wcavi_original_gallery_images, wcavi_original_main_images ] );

                $.wc_additional_variation_images_frontend.initProductGallery();

                if ( callback ) {
                    callback();
                }
                
            },

            imageSwap: function( response, callback ) {
                if ( wc_additional_variation_images_local.custom_swap == true ) {
                    $( 'form.variations_form' ).trigger( 'wc_additional_variation_images_frontend_image_swap_callback', [ response, wc_additional_variation_images_local.gallery_images_class, wc_additional_variation_images_local.main_images_class, wcavi_original_gallery_images, wcavi_original_main_images ] );

                } else {
                    if ( ! wc_additional_variation_images_local.bwc ) {
                        var parent = $( wc_additional_variation_images_local.main_images_class ).parent();
                        var width  = $( '.woocommerce-product-gallery__image' ).outerWidth();
                        var height = $( '.woocommerce-product-gallery__image' ).outerHeight();

                        $( wc_additional_variation_images_local.main_images_class ).remove();
                        $.when( parent.prepend( response.main_images ) ).then( function() {
                            $( '.woocommerce-product-gallery__image' ).width( width ).height( height );
                            $( '.woocommerce-product-gallery__wrapper' ).width( width ).height( height );
                        });
                    } else {
                        $( wc_additional_variation_images_local.gallery_images_class ).fadeOut( 50, function() {
                            $( this ).html( response.gallery_images ).hide().fadeIn( 100, function() {
                                $.wc_additional_variation_images_frontend.runLightBox();
                            });
                        });
                    }
                }

                $( 'form.variations_form' ).trigger( 'wc_additional_variation_images_frontend_image_swap_done_callback', [ wc_additional_variation_images_local.gallery_images_class, wc_additional_variation_images_local.main_images_class, wcavi_original_gallery_images, wcavi_original_main_images ] );


                $.wc_additional_variation_images_frontend.initProductGallery();

                if ( callback ) {
                    callback();
                }
                
                $('.woocommerce div.product div.images .flex-control-thumbs').after('<div class="thumb_control_prev"><div class="arrow"></div></div>');
                $('.woocommerce div.product div.images .flex-control-thumbs').after('<div class="thumb_control_next"><div class="arrow"></div></div>');
                
                
                scrollObj = $('.flex-control-thumbs');
                $('.thumb_control_prev').click(function() {
                  scrollObj.stop().animate({ scrollTop: "0" });
                });

                $('.thumb_control_prev').mouseenter(function() {
                  scrollObj.stop().animate({ scrollTop: "0" }, 3000);
                });

                $('.thumb_control_prev').mouseleave(function() {
                  scrollObj.stop();
                });

                $('.thumb_control_next').click(function() {
                  scrollObj.stop().animate({ scrollTop: scrollObj[0].scrollHeight + "px" });
                });

                $('.thumb_control_next').mouseenter(function() {
                  scrollObj.stop().animate({ scrollTop: scrollObj[0].scrollHeight + "px" }, 3000);
                });

                $('.thumb_control_next').mouseleave(function() {
                  scrollObj.stop();
                });
            },

            imageSwapOriginal: function( callback ) {

                if ( wc_additional_variation_images_local.custom_original_swap == true ) {
                    var response = '';

                    $( 'form.variations_form' ).trigger( 'wc_additional_variation_images_frontend_ajax_default_image_swap_callback', [ response, wc_additional_variation_images_local.gallery_images_class, wc_additional_variation_images_local.main_images_class, wcavi_original_gallery_images, wcavi_original_main_images ] );

                } else {
                    $( wc_additional_variation_images_local.gallery_images_class ).fadeOut( 50, function() {
                        $( this ).html( wcavi_original_gallery_images ).hide().fadeIn( 100, function() {
                            $.wc_additional_variation_images_frontend.runLightBox();
                        });
                    });
                }

                $( 'form.variations_form' ).trigger( 'wc_additional_variation_images_frontend_ajax_default_image_swap_done_callback', [ wc_additional_variation_images_local.gallery_images_class, wc_additional_variation_images_local.main_images_class, wcavi_original_gallery_images, wcavi_original_main_images ] );

                $.wc_additional_variation_images_frontend.initProductGallery();

                if ( callback ) {
                    callback();
                }
            },

            hideGallery: function() {
                $( wc_additional_variation_images_local.gallery_images_class ).hide().css( 'visibility', 'hidden' );
            },

            showGallery: function() {
                $( wc_additional_variation_images_local.gallery_images_class ).css( 'visibility', 'visible' ).fadeIn( 'fast' );
            },

            initProductGallery: function() {
                $( '.woocommerce-product-gallery' ).each( function() {
                    $( this ).wc_product_gallery();
                } );
            },

            getImages: function( data ) {
                return $.ajax({
                    type:    'POST',
                    data:    data,
                    url:     $.wc_additional_variation_images_frontend.getAjaxURL( 'get_images' )
                });
            },

            init: function() {
                // when variation changes trigger. this is used for WC 3.0 only.
                if ( ! wc_additional_variation_images_local.bwc ) {
                    $( 'form.variations_form' ).on( 'reset_image', function( event, variation ) {
                        $( 'form.variations_form' ).trigger( 'wc_additional_variation_images_frontend_reset_variation' );

                        $( wc_additional_variation_images_local.gallery_images_class ).block({
                            message: null,
                            overlayCSS: {
                                background: '#fff',
                                opacity: 0.6
                            }
                        });

                        var data = {
                            security: wc_additional_variation_images_local.ajaxImageSwapNonce,
                            post_id: $( 'form.variations_form' ).data( 'product_id' )
                        };

                        $.when( $.wc_additional_variation_images_frontend.getImages( data ) ).then( function( response ) {
                            if ( response ) {

                                $( 'form.variations_form' ).trigger( 'wc_additional_variation_images_frontend_ajax_response_callback', [ response, wc_additional_variation_images_local.gallery_images_class, wc_additional_variation_images_local.main_images_class, wcavi_original_gallery_images, wcavi_original_main_images ] );
        
                                // replace with new image set
                                $.wc_additional_variation_images_frontend.imageSwap( response );

                            } else {
        
                                $( 'form.variations_form' ).trigger( 'wc_additional_variation_images_frontend_ajax_response_callback', [ response, wc_additional_variation_images_local.gallery_images_class, wc_additional_variation_images_local.main_images_class, wcavi_original_gallery_images, wcavi_original_main_images ] );
        
                                // replace with original image set
                                $.wc_additional_variation_images_frontend.imageSwapOriginal();
                            }
                            
                            $( wc_additional_variation_images_local.gallery_images_class ).unblock();
                        });
                    });
                }

                // when variation changes trigger
                $( 'form.variations_form' ).on( 'show_variation', function( event, variation ) {
                    $( 'form.variations_form' ).trigger( 'wc_additional_variation_images_frontend_before_show_variation' );

                    $( wc_additional_variation_images_local.gallery_images_class ).block({
                        message: null,
                        overlayCSS: {
                            background: '#fff',
                            opacity: 0.6
                        }
                    });

                    var data = {
                        security: wc_additional_variation_images_local.ajaxImageSwapNonce,
                        variation_id: variation.variation_id,
                        post_id: $( 'form.variations_form' ).data( 'product_id' )
                    };

                    $.when( $.wc_additional_variation_images_frontend.getImages( data ) ).then( function( response ) {
                        if ( response ) {

                            $( 'form.variations_form' ).trigger( 'wc_additional_variation_images_frontend_ajax_response_callback', [ response, wc_additional_variation_images_local.gallery_images_class, wc_additional_variation_images_local.main_images_class, wcavi_original_gallery_images, wcavi_original_main_images ] );

                            // replace with new image set
                            $.wc_additional_variation_images_frontend.imageSwap( response );

                        } else {

                            $( 'form.variations_form' ).trigger( 'wc_additional_variation_images_frontend_ajax_response_callback', [ response, wc_additional_variation_images_local.gallery_images_class, wc_additional_variation_images_local.main_images_class, wcavi_original_gallery_images, wcavi_original_main_images ] );

                            // replace with original image set
                            $.wc_additional_variation_images_frontend.imageSwapOriginal();
                        }

                        $( wc_additional_variation_images_local.gallery_images_class ).unblock();
                    });
                });

                // on reset click
                $( 'form.variations_form' ).on( 'click', '.reset_variations', function() {
                    $.wc_additional_variation_images_frontend.reset();
                });

                // on reset select trigger
                $( 'form.variations_form' ).on( 'reset_image', function() {
                    $.wc_additional_variation_images_frontend.reset();
                });

                // add support for swatches and photos plugin
                $( '#variations_clear' ).on( 'click', function() {
                    $.wc_additional_variation_images_frontend.reset();
                });

                $( '.swatch-anchor' ).on( 'click', function() {
                    var option = $( this ).parent( '.select-option' );

                    if ( option.hasClass( 'selected' ) ) {
                        $.wc_additional_variation_images_frontend.reset();
                    }
                });

                $( 'form.variations_form' ).trigger( 'wc_additional_variation_images_frontend_init', [ wc_additional_variation_images_local.gallery_images_class, wc_additional_variation_images_local.main_images_class, wcavi_original_gallery_images, wcavi_original_main_images ] );
            }
        }; // close namespace

        $.wc_additional_variation_images_frontend.init();
        
        /* WC ADDITIONAL IMAGES OVERRIDE END */

        $( '.cart' ).each( function() {

        $('.single_add_to_cart_button').html("add to bag - " + $('.woocommerce-Price-amount').html().replace(".00",""));

        // when variation is found, do something
        $(this).on( 'found_variation', function( event, variation ) {
          if(variation.price_html){
            $('.single_add_to_cart_button').html("add to bag - " + variation.price_html.replace(".00",""));
          }
        });

        $('.detail').click(function() {
          $(this).addClass('active');
          $('.variation').removeClass('active');
          $( ".woocommerce-product-details__short-description" ).addClass("hide");
          $( ".variations" ).removeClass("hide");
        });

        $('.variation').click(function() {
          $(this).addClass('active');
          $('.detail').removeClass('active');
          $( ".woocommerce-product-details__short-description" ).removeClass("hide");
          $( ".variations" ).addClass("hide");
        });

        $('.shipping_returns').click(function() {
          if(window.innerWidth>=768){
            swap = 525-scrollWidth;
          }else{
            swap = window.innerWidth-scrollWidth;
          }
          if($('.innerSlide').position().left===0){
            $('.nav-shipping').stop().animate({ left: $('.innerSlide').width()-swap+"px" }, 750, "easeInOutCirc" );
            $(".innerSlide").stop().animate({ left: "-"+swap+"px" }, 750, "easeInOutCirc" );
            $('body').addClass('nav_open');
          }else{
            $('.nav-shipping').stop().animate({ left: $('.innerSlide').width() }, 750, "easeInOutCirc" );
            $(".innerSlide").stop().animate({ left: 0 +"px" }, 750, "easeInOutCirc" );
            $('body').removeClass('nav_open');
          }
        });

        $('.single_add_to_cart_button').click(function(e){

          e.preventDefault();
          var $thisbutton = $(this),
                $form = $thisbutton.closest('form.cart'),
                id = $thisbutton.val(),
                product_qty = $form.find('input[name=quantity]').val() || 1,
                product_id = $form.find('input[name=product_id]').val() || id,
                variation_id = $form.find('input[name=variation_id]').val() || 0;
 
          var data = {
              action: 'woocommerce_ajax_add_to_cart',
              product_id: product_id,
              product_sku: '',
              quantity: product_qty,
              variation_id: variation_id,
          };
   
          $(document.body).trigger('adding_to_cart', [$thisbutton, data]);
   
          $.ajax({
              type: 'post',
              url: wc_add_to_cart_params.ajax_url,
              data: data,
              beforeSend: function (response) {
                  $thisbutton.removeClass('added').addClass('loading');
              },
              success: function (response) {
   
                  jQuery.ajax({
                      url : woocommerce_params.ajax_url,
                      type : 'post',
                      data : {
                          'action': 'ajax_update_mini_cart'
                      },
                      success : function( response ) {
                          $('#mini-cart-container').html(response);
                      },
                      complete: function (response) {
                          $thisbutton.removeClass('loading');
                      },
                  });

                  if (response.error && response.product_url) {
                      //window.location = response.product_url;
                      return;
                  } else {
                      $thisbutton.addClass('added');
                       $('.cart-customlocation-wrap').click();
                       $('.single_add_to_cart_button').html("added");
                      $(document.body).trigger('added_to_cart', [response.fragments, response.cart_hash, $thisbutton]);
                  }
              },
          });

          return false;
        });
    });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        window.onload = function(e){
          var pos = $('.flex-control-thumbs li:last').offset();
          if(pos){
            var updateArrow = pos.top;
            
            if(updateArrow>493){
              updateArrow=493;
            }
            $('.thumb_control_next').css('top', updateArrow + 92 );
          }
          $( window ).resize();
          $('h1, h2, h3').addClass('active');
        };
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
